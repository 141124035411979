import makeFormValidator from './make_form_validator';
import { loginFormSchema } from './login_form_schema';
import { consignmentFormSchema } from './consignment_form_schema';
import { consignmentItemFormSchema } from './consignment_item_form_schema';
import { consignmentImageFormSchema } from './consignment_image_form_schema';
import { picklistFormSchema } from './picklist_form_schema';
import { picklistProductFormSchema } from './picklist_product_form_schema';
import { picklistSupplierCatalogItemFormSchema } from './picklist_supplier_catalog_item_form_schema';

import { picklistTemplateFormSchema } from './picklist_template_form_schema';
import { picklistTemplateProductFormSchema } from './picklist_template_product_form_schema';
import { productFormSchema } from './product_form_schema';
import { productProductFormSchema } from './product_product_form_schema';
import { productSupplierCatalogItemFormSchema } from './product_supplier_catalog_item_form_schema';
import { receiptFormSchema, goodsReceiptFormSchema } from './receipt_form_schema';
import { siteFormSchema } from './site_form_schema';
import { siteLocationFormSchema } from './site_location_form_schema';
import { userFormSchema } from './user_form_schema';
import { wbsCodeFormSchema } from './wbs_code_form_schema';

import { supplierFormSchema } from './supplier_form_schema';
import { supplierCatalogFormSchema } from './supplier_catalog_form_schema';
import { supplierCatalogItemFormSchema } from './supplier_catalog_item_form_schema';
import { productCategoryFormSchema } from './product_category_form_schema';
import { purchaserFormSchema } from './purchaser_form_schema';
import { manufacturerFormSchema } from './manufacturer_form_schema';

export const supplierFormValidator = makeFormValidator(supplierFormSchema);
export const supplierCatalogFormValidator = makeFormValidator(supplierCatalogFormSchema);
export const supplierCatalogItemFormValidator = makeFormValidator(
  supplierCatalogItemFormSchema
);
export const productCategoryFormValidator = makeFormValidator(productCategoryFormSchema);
export const purchaserFormValidator = makeFormValidator(purchaserFormSchema);
export const manufacturerFormValidator = makeFormValidator(manufacturerFormSchema);

export const loginFormValidator = makeFormValidator(loginFormSchema);
export const consignmentFormValidator = makeFormValidator(consignmentFormSchema);
export const consignmentItemFormValidator = makeFormValidator(consignmentItemFormSchema);
export const consignmentImageFormValidator = makeFormValidator(
  consignmentImageFormSchema
);
export const goodsReceiptFormValidator = makeFormValidator(goodsReceiptFormSchema);
export const picklistFormValidator = makeFormValidator(picklistFormSchema);
export const picklistProductFormValidator = makeFormValidator(picklistProductFormSchema);
export const picklistSupplierCatalogItemFormValidator = makeFormValidator(
  picklistSupplierCatalogItemFormSchema
);

export const picklistTemplateFormValidator = makeFormValidator(
  picklistTemplateFormSchema
);
export const picklistTemplateProductFormValidator = makeFormValidator(
  picklistTemplateProductFormSchema
);
export const productFormValidator = makeFormValidator(productFormSchema);
export const productProductFormValidator = makeFormValidator(productProductFormSchema);
export const productSupplierCatalogItemFormValidator = makeFormValidator(
  productSupplierCatalogItemFormSchema
);
export const receiptFormValidator = makeFormValidator(receiptFormSchema);
export const siteFormValidator = makeFormValidator(siteFormSchema);
export const siteLocationFormValidator = makeFormValidator(siteLocationFormSchema);
export const userFormValidator = makeFormValidator(userFormSchema);
export const wbsCodeFormValidator = makeFormValidator(wbsCodeFormSchema);
