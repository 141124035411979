import reduce from 'lodash.reduce';
import omit from 'lodash.omit';
import pick from 'lodash.pick';

import {
  consignmentOptimisticList,
  consignmentItemOptimisticList,
  consignmentImageOptimisticList,
  picklistOptimisticList,
  picklistProductOptimisticList,
  picklistSupplierCatalogItemOptimisticList,
  picklistTemplateOptimisticList,
  picklistTemplateProductOptimisticList,
  productOptimisticList,
  productProductOptimisticList,
  productSupplierCatalogItemOptimisticList,
  receiptOptimisticList,
  siteOptimisticList,
  siteLocationOptimisticList,
  userOptimisticList,
  supplierOptimisticList,
  supplierCatalogOptimisticList,
  supplierCatalogItemOptimisticList,
  productCategoryOptimisticList,
  purchaserOptimisticList,
  manufacturerOptimisticList,
  wbsCodeOptimisticList,
} from '../white_lists';

// all keys needed to write a fragment.  Optimistic responses that don't define all these keys
// will fail, often silently or breaking queries for reaons that are hard to debug.
// Basically whatever fragment you're using in the writeFragment you need every one of those keys
// Must be a better way to do this
// At this stage using the whitelists used to pick form values seems to work
const requiredForType = {
  ConsignmentType: consignmentOptimisticList,
  ConsignmentItemType: consignmentItemOptimisticList,
  ConsignmentImageType: consignmentImageOptimisticList,
  PicklistType: picklistOptimisticList,
  PicklistProductType: picklistProductOptimisticList,
  PicklistSupplierCatalogItemType: picklistSupplierCatalogItemOptimisticList,
  PicklistTemplateType: picklistTemplateOptimisticList,
  PicklistTemplateProductType: picklistTemplateProductOptimisticList,
  ProductType: productOptimisticList,
  ProductProductType: productProductOptimisticList,
  ProductSupplierCatalogItemType: productSupplierCatalogItemOptimisticList,
  ReceiptType: receiptOptimisticList,
  SiteType: siteOptimisticList,
  SiteLocationType: siteLocationOptimisticList,
  UserType: userOptimisticList,
  SupplierType: supplierOptimisticList,
  SupplierCatalogType: supplierCatalogOptimisticList,
  SupplierCatalogItemType: supplierCatalogItemOptimisticList,
  ProductCategoryType: productCategoryOptimisticList,
  PurchaserType: purchaserOptimisticList,
  ManufacturerType: manufacturerOptimisticList,
  WbsCodeType: wbsCodeOptimisticList,
};

export const optimistic = (
  operationName,
  mutationData,
  extraData = {},
  omitKeys = []
) => ({
  __typename: 'Mutation',
  [operationName]: {
    __typename: mutationData.context.recordType,
    ...(mutationData.context.mutationType === 'CREATE' &&
      reduce(
        requiredForType[mutationData.context.recordType] || [],
        (r, k) => ({ ...r, [k]: null }),
        {}
      )),
    ...(mutationData.context.recordId && { id: mutationData.context.recordId }),
    ...(mutationData.variables.input && omit(mutationData.variables.input, omitKeys)),
    ...extraData,
  },
});

export const optimisticNew = ({
  mutationName,
  mutationData,
  extraData = {},
  currentData = {},
  omitKeys = [],
}) => {
  const {
    context: { mutationType, recordType, recordId },
    variables: { input },
  } = mutationData;
  const requiredKeys = requiredForType[recordType] || [];
  const payload = {
    __typename: recordType,
    ...(mutationType === 'CREATE' &&
      reduce(requiredKeys, (r, k) => ({ ...r, [k]: null }), {})),
    ...(mutationType === 'UPDATE' && omit(pick(currentData, requiredKeys), omitKeys)),
    ...(recordId && { id: recordId }),
    ...(input && omit(input, omitKeys)),
    ...extraData,
  };
  return {
    __typename: 'Mutation',
    [mutationName]: payload,
  };
};

export default optimistic;
