export const releaseNotes = [
  {
    releasedOn: '2024-11-06',
    releaseNote: `\
Populate deliveredAt for consignment items based on their receipt record
Update consignment items and consignment list to use new table filter/sort/paginate
Improve natural sort of dropdown table entries
`,
  },
  {
    releasedOn: '2024-11-05',
    releaseNote: `\
Fix First Scanned sorting on receipts list
Add Last Seen column to receipts list
Add Man (manual) column to receipts list to allow filtering of receipts
Update supplier catalog item unit of measurement enums to include pallets
Record current user id when updating picked/unpicked/picked checked state changes on pick lists
Show unit of measurement on picklist show page (e.g. 400/bag)
Add this release notes modal
Add a 1 minute poll (auto refetch) to receipts list
`,
  },
];
