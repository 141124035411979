import { useEffect, useMemo, useState } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Badge,
  Modal,
  ButtonGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AlertCircle as AlertCircleIcon,
  Wifi as WifiIcon,
  WifiOff as WifiOffIcon,
  // Sliders as SlidersIcon,
  // Users as UsersIcon,
  // Lock as LockIcon,
} from 'react-feather';

import Confirm from '../components/confirm';
import DlHorizontal from '../components/dl_horizontal';
import { clearLocalStorage, clearLocalSettings } from '../lib/local_storage';
// import Glyphicon from '../components/glyphicon';
// import { settingsSet } from '../store/settings_slice';
import { releaseNotes } from '../release_notes';

import { renderMultlilineText } from '../lib/utils';

export default function Header() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showReleaseModalModal, setShowReleaseModal] = useState(false);
  const settingsOnline = useSelector((state) => state.settings.online);
  const currentUser = useSelector((state) => state.auth.user);
  const versionCurrent = useSelector((state) => state.settings.versionCurrent);
  const trackedMutationCount = useSelector(
    (state) => state.settings.trackedMutationCount
  );
  const currentBootswatchStyle = useSelector((state) => state.settings.bootswatchStyle);
  const tenantShortName = useSelector((state) => state.settings.tenantShortName);

  const perms = useMemo(() => {
    if (currentUser?.id) {
      return currentUser.perms;
    }
    return {};
  }, [currentUser]);

  useEffect(() => {
    const link = document.querySelector('link[title=bootswatch]');
    if (link) {
      const href = link.getAttribute('href');
      if (href) {
        const newHref = href.replace(
          /dist\/.+\/bootstrap.min.css/,
          `dist/${currentBootswatchStyle}/bootstrap.min.css`
        );
        link.setAttribute('href', newHref);
      }
    }
  }, [currentBootswatchStyle]);

  const handleReleaseModalClick = () => {
    setShowReleaseModal(true);
  };

  const handleReleaseModalCancel = () => {
    setShowReleaseModal(false);
  };

  const onClearStorageClicked = async () => {
    await clearLocalStorage();
    window.location.reload(true);
  };

  const onClearSettingsClicked = async () => {
    await clearLocalSettings();
    window.location.reload(true);
  };

  const onResetClicked = () => {
    navigate('/auth/logout?reset');
  };

  const onReloadClicked = () => {
    window.location.reload(true);
  };

  // const handleStyleChange = (bootswatchStyle) =>
  //   dispatch(
  //     settingsSet({
  //       bootswatchStyle,
  //     })
  //   );

  return (
    <>
      <Modal
        id="header-release-notes"
        show={showReleaseModalModal}
        onHide={handleReleaseModalCancel}
        centered
        size="lg"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Release Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: 'calc(66vh)', overflowY: 'scroll' }}>
            {releaseNotes.map(({ releasedOn, releaseNote }) => (
              <DlHorizontal
                key={releasedOn}
                dtWidth={3}
                ddWidth={9}
                dt={releasedOn}
                dd={renderMultlilineText(releaseNote)}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="d-flex justify-content-end w-100">
            <ButtonGroup className="me-2">
              <Button variant="danger" onClick={handleReleaseModalCancel}>
                Close
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>

      <Navbar
        // defaultExpanded={false}
        expand="md"
        variant="light"
        bg="light"
        className="px-4"
      >
        <Navbar.Brand href="/" className="text-dark">
          {tenantShortName}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div className="w-100 d-flex justify-content-between flex-nowrap">
          <Navbar.Collapse id="left-navbar-nav" className="justify-content-start">
            <Nav>
              <Nav.Item className="me-2">
                <LinkContainer to={{ pathname: '/goods_receipt' }}>
                  <Nav.Link>New Receipt</Nav.Link>
                </LinkContainer>
              </Nav.Item>
              {perms.manager && (
                <NavDropdown
                  title="Consignments"
                  id="basic-nav-dropdown-other"
                  className="me-2"
                >
                  <LinkContainer to={{ pathname: '/receipts' }}>
                    <NavDropdown.Item eventKey={3.1}>Receipts</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to={{ pathname: '/consignments' }}>
                    <NavDropdown.Item eventKey={3.2}>Consignments</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/consignment_items' }}>
                    <NavDropdown.Item eventKey={3.3}>Consignment Items</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/consignment_images' }}>
                    <NavDropdown.Item eventKey={3.4}>Consignment Images</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to={{ pathname: '/sites' }}>
                    <NavDropdown.Item eventKey={3.5}>Sites</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/site_locations' }}>
                    <NavDropdown.Item eventKey={3.6}>Site Locations</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {perms.manager && (
                <NavDropdown
                  title="Picklists"
                  id="basic-nav-dropdown-suppliers"
                  className="me-2"
                >
                  <LinkContainer to={{ pathname: '/picklists' }}>
                    <NavDropdown.Item eventKey={4.1}>Picklists</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to={{ pathname: '/picklist_templates' }}>
                    <NavDropdown.Item eventKey={4.2}>Picklist Templates</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}

              {perms.manager && (
                <NavDropdown
                  title="Products"
                  id="basic-nav-dropdown-suppliers"
                  className="me-2"
                >
                  <LinkContainer to={{ pathname: '/products' }}>
                    <NavDropdown.Item eventKey={5.0}>Products</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to={{ pathname: '/product_categories' }}>
                    <NavDropdown.Item eventKey={5.1}>Product Categories</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/purchasers' }}>
                    <NavDropdown.Item eventKey={5.2}>Purchasers</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/manufacturers' }}>
                    <NavDropdown.Item eventKey={5.3}>Manufacturers</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/suppliers' }}>
                    <NavDropdown.Item eventKey={5.4}>Suppliers</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/supplier_catalogs' }}>
                    <NavDropdown.Item eventKey={5.5}>Supplier Catalogs</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/supplier_catalog_items' }}>
                    <NavDropdown.Item eventKey={5.6}>
                      Supplier Catalog Items
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {perms.administrator && (
                <NavDropdown title="Admin" id="basic-nav-dropdown-other" className="me-2">
                  <LinkContainer to={{ pathname: '/users' }}>
                    <NavDropdown.Item eventKey={6.1}>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/wbs_codes' }}>
                    <NavDropdown.Item eventKey={6.2}>WbsCodes</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {perms.developer && (
                <NavDropdown
                  title="Developer"
                  id="basic-nav-dropdown-developer"
                  className="me-2"
                >
                  <LinkContainer to={{ pathname: '/bom_imports/new' }}>
                    <NavDropdown.Item eventKey={7.1}>New Bom Import</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/srack_imports/new' }}>
                    <NavDropdown.Item eventKey={7.2}>
                      New S-Rack Loading Plan Import
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/srack_update_imports/new' }}>
                    <NavDropdown.Item eventKey={7.3}>
                      New S-Rack Loading Plan Import Update
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/canadian_solar_imports/new' }}>
                    <NavDropdown.Item eventKey={7.4}>
                      New Canadian Solar Import
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={{ pathname: '/wbs_code_imports/new' }}>
                    <NavDropdown.Item eventKey={7.1}>
                      New WBS Code Import
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {versionCurrent || (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-header-version-link">
                      Your application version is out of date. Click to refresh your
                      browser
                    </Tooltip>
                  }
                >
                  <Button variant="danger" onClick={onReloadClicked}>
                    <AlertCircleIcon size="16" />
                  </Button>
                </OverlayTrigger>
              )}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="right-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Item className="me-3">
                {settingsOnline ? (
                  <OverlayTrigger
                    key="online"
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-online">You are online</Tooltip>}
                  >
                    <WifiIcon />
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    key="offline"
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-offline">
                        You are offline. Not all data may be available
                      </Tooltip>
                    }
                  >
                    <WifiOffIcon color="red" />
                  </OverlayTrigger>
                )}
              </Nav.Item>
              <Nav.Item className="me-2">
                <OverlayTrigger
                  key="online"
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-online">Sync Queue</Tooltip>}
                >
                  <Badge
                    pill
                    variant={trackedMutationCount > 0 ? 'danger' : 'secondary'}
                    size="lg"
                  >
                    {trackedMutationCount}
                  </Badge>
                </OverlayTrigger>
              </Nav.Item>
            </Nav>
            {/* <Nav activeKey={currentBootswatchStyle} onSelect={handleStyleChange}>
            <NavDropdown title="Themes" id="basic-nav-dropdown-themes">
              <NavDropdown.Item eventKey="simplex">Simplex</NavDropdown.Item>
              <NavDropdown.Item eventKey="sandstone">Sandstone</NavDropdown.Item>
              <NavDropdown.Item eventKey="darkly">Darkly</NavDropdown.Item>
              <NavDropdown.Item eventKey="zephyr">Zephyr</NavDropdown.Item>
              <NavDropdown.Item eventKey="cosmo">Cosmo</NavDropdown.Item>
              <NavDropdown.Item eventKey="flatly">Flatly</NavDropdown.Item>
              <NavDropdown.Item eventKey="sketchy">Sketchy</NavDropdown.Item>
              <NavDropdown.Item eventKey="spacelab">Spacelab</NavDropdown.Item>
              <NavDropdown.Item eventKey="journal">Journal</NavDropdown.Item>
              <NavDropdown.Item eventKey="morph">Morph</NavDropdown.Item>
              <NavDropdown.Item eventKey="yeti">Yeti</NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
            <Nav>
              <NavDropdown align="end" title="Tools" id="basic-nav-dropdown-other">
                <NavDropdown.Header>{currentUser.email}</NavDropdown.Header>
                <NavDropdown.Item
                  eventKey={8.1}
                  href="#"
                  onClick={handleReleaseModalClick}
                >
                  Release Notes
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey={8.2}
                  href="#"
                  onClick={() => alert('Coming soon...')}
                >
                  Change password
                </NavDropdown.Item>

                <NavDropdown.Item eventKey={8.3} href="#" onClick={onClearStorageClicked}>
                  Clear Cache
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey={8.3}
                  href="#"
                  onClick={onClearSettingsClicked}
                >
                  Clear Settings
                </NavDropdown.Item>
                <Confirm
                  onConfirm={onResetClicked}
                  title="Reset all"
                  body="This will reset the service and log you out. Any changes made while offline will be lost."
                >
                  <NavDropdown.Item eventKey={8.4} href="#">
                    Reset
                  </NavDropdown.Item>
                </Confirm>
                <LinkContainer to={{ pathname: '/auth/logout' }}>
                  <NavDropdown.Item eventKey={8.5}>Sign Out</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
